const menu = [
  { href: '/textes', label: 'Découvrir les textes' },
  { href: '/ecrire-un-texte', label: 'Écrire un texte' },
  { href: '/publications', label: 'Publications' },
  { href: '/agenda', label: 'Agenda' },
  { href: '/creations?type=sounds', label: 'Créations' },
  { href: '/ateliers', label: 'Ateliers et résidences' },
];

export default menu;
