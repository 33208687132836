import { Box, MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { useMediaQuery } from '@mantine/hooks';

import RootMenu from '../components/RootMenu';
import Footer from '../components/Footer';
import theme from '../config/theme';
import '../styles/globals.css';
import Header from '../components/Header';
import Head from 'next/head';
import { emotionCache } from '@/config/emotionCache';
import Script from 'next/script';

function MyApp({ Component, pageProps }) {
  const isMobile = useMediaQuery('(max-width: 800px)');
  console.log(process.env.NODE_ENV);

  return (
    <>
      <Head>
        <title>L'Épître</title>
        <meta
          name='description'
          content='L’Épître est une revue littéraire qui a pour but d’encourager à l’écriture. Menée par une équipe de professionnel·le·s en littérature, elle poursuit un travail de critiques et d’accompagnement à travers une double plateforme.'
        />
      </Head>

      {process.env.NODE_ENV === 'production' && (
        <Script
          src='https://analytics.cloud.innovation-it.ch/script.js'
          data-website-id='ac07ea95-076c-4bea-a341-f13efbc23d73'
        />
      )}

      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={theme}
        emotionCache={emotionCache}
      >
        <NotificationsProvider position='bottom-center'>
          <Header isMobile={isMobile} />

          <RootMenu isMobile={isMobile} />

          <div
            style={{
              // marginTop: 1,
              // height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Component {...pageProps} />

            <Footer />
          </div>
        </NotificationsProvider>
      </MantineProvider>
    </>
  );
}

export default MyApp;
